@use '../variables' as *;

@mixin focus-ring-theme() {
  box-shadow: 0 0 1px 2px rgba(95, 140, 201, 0.23);
  outline: none;
}

@mixin input-container-theme() {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: normal;
  color: $cr-theme-midnight-100;
  text-align: left;
  font-size: 17px;
  line-height: 1.41;
  width: 100%;
  background-color: #fff;
  border: 1px solid #9AB1D4;
  border-radius: 6px;
  transition: background-color 0.3s ease,
              border-color 0.3s ease,
              color 0.3s ease;
}

@mixin input-container-hovered-theme() {
  border-color: #8b9bbf;
}

@mixin input-container-focused-theme() {
  border-color: #8b9bbf;
  @include focus-ring-theme();
}

@mixin input-container-error-theme() {
  border-color: $cr-brand-danger;
}

@mixin input-container-readonly-theme() {
  color: $cr-theme-midnight-100;
  background-color: #f3f3f3;
  border-color: #D0E0F8;
}

@mixin input-container-disabled-theme() {
  color: #7C93B7;
  background-color: #F9FBFF;
  border: 1px solid #D0E0F8;
}

@mixin input-placeholder-theme() {
  color: #BAD0F1;
}

@mixin input-theme() {
  @include input-container-theme();
  padding: 7px 7px;

  &:hover {
    @include input-container-hovered-theme();
  }

  &:focus {
    @include input-container-focused-theme();
  }

  &--readonly,
  &[readonly] {
    @include input-container-readonly-theme();
  }

  &--disabled,
  &[disabled] {
    @include input-container-disabled-theme();
    cursor: default;
  }

  &::placeholder {
    @include input-placeholder-theme();
  }
}

@mixin input-clear-button-theme() {
  color: $cr-theme-gray-30;
  transition: color 0.3s ease;

  &:hover {
    color: #333c50;
  }

  &:focus {
    color: #333c50;
  }
}


@mixin cr-checkbox-container() {
  position: relative;

  input[type="checkbox"] {
    display: none;
  }

  label {
    cursor: pointer;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    border-radius: 4px;

    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), 0 1px 0 rgba(255, 255, 255, 0.4);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), 0 1px 0 rgba(255, 255, 255, 0.4);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), 0 1px 0 rgba(255, 255, 255, 0.4);

    background-color: $primary-bg;
  }

  label:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    width: 13px;
    height: 8px;
    background: transparent;
    top: 4px;
    left: 4px;
    border: 3px solid #fcfff4;
    border-top: none;
    border-right: none;

    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  label:hover::after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    filter: alpha(opacity=30);
    opacity: 0.3;
  }

  input[type=checkbox]:checked + label:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
  }
}

@use '../../assets/styles/variables';
@use '../../assets/styles/mixins/adaptive';

.content-container {
  padding: 30px 48px;
  width: 100%;
  height: 100%;

  @include adaptive.respond(mobile) {
    padding: 20px 10px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @include adaptive.respond(mobile) {
      justify-content: center;
    }
  }

  &__header-content {
    display: flex;
    flex-basis: 40%;
  }

  &__back-link {
    display: flex;
    align-items: center;
    font-size: 22px;
    color: variables.$cr-theme-midnight-90;
  }

  &__back-icon {
    margin-right: 4px;
  }

  &__title {
    font-weight: bold;
    font-size: 40px;
    line-height: 1.25;
    color: variables.$cr-brand-default;
    white-space: nowrap;

    @include adaptive.respond(mobile) {
      font-size: 26px;
    }
  }

  &__loader {
    margin-left: 10px;
  }

  &__content {
    background-color: variables.$cr-theme-gray-10;
    padding: 28px;
    margin-top: 28px;
    border-radius: 16px;

    @include adaptive.respond(mobile) {
      background-color: unset;
      padding: 0;
      margin-top: 16px;
    }
  }

  &--without-bg &__content {
    padding: 0;
    background-color: unset;
  }
}

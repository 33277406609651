@use 'src/assets/styles/variables';

.d-avatar {
  position: relative;
  z-index: 1;

  &--size-xs {
    width: 32px;
    height: 32px;
  }

  &--size-s {
    width: 40px;
    height: 40px;
  }

  &--size-m {
    width: 54px;
    height: 54px;
  }

  &--size-l {
    width: 120px;
    height: 120px;
  }

  &--size-xl {
    width: 200px;
    height: 200px;
  }

  &--size-xxl {
    width: 252px;
    height: 252px;
  }
}

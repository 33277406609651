@use '../../assets/styles/mixins/inputs';

.cr-number-input {
  @include inputs.input-theme();
  padding-right: 12px;
  padding-left: 12px;

  &--hide-arrow-buttons {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }

    & {
      -moz-appearance: textfield;
      touch-action: none;
    }
  }
}

// Theme colors
$primary-bg: #333c4f;

// Orange
$cr-theme-orange-10: #FFF5EA;
$cr-theme-orange-20: #FFDEBD;
$cr-theme-orange-30: #FFC78F;
$cr-theme-orange-40: #FFB061;
$cr-theme-orange-50: #FF9933; // main
$cr-theme-orange-60: #DD7F20;
$cr-theme-orange-70: #BB6611;
$cr-theme-orange-80: #995006;
$cr-theme-orange-90: #773C00;
$cr-theme-orange-100: #552B00;

// Sea
$cr-theme-sea-10: #EEFFFD;
$cr-theme-sea-20: #C9FFFA;
$cr-theme-sea-30: #A3FFF7;
$cr-theme-sea-40: #7EFFF3;
$cr-theme-sea-50: #58FFF0;
$cr-theme-sea-60: #41E2D4;
$cr-theme-sea-70: #2CC0B3; // main
$cr-theme-sea-80: #1B9E92;
$cr-theme-sea-90: #0E7C72;
$cr-theme-sea-100: #055A53;

// Blue
$cr-theme-blue-10: #E5FAFF;
$cr-theme-blue-20: #B7F0FF;
$cr-theme-blue-30: #8AE7FF;
$cr-theme-blue-40: #5CDEFF;
$cr-theme-blue-50: #2ED5FF;
$cr-theme-blue-60: #00CCFF; // main
$cr-theme-blue-70: #00ABD6;
$cr-theme-blue-80: #008BAD;
$cr-theme-blue-90: #006A85;
$cr-theme-blue-100: #00495C;

// Midnight
$cr-theme-midnight-10: #F9FBFF;
$cr-theme-midnight-20: #E8F1FF;
$cr-theme-midnight-30: #D0E0F8;
$cr-theme-midnight-40: #BAD0F1;
$cr-theme-midnight-50: #9AB1D4;
$cr-theme-midnight-60: #7C93B7;
$cr-theme-midnight-70: #627899;
$cr-theme-midnight-80: #495D7C;
$cr-theme-midnight-90: #34455F;
$cr-theme-midnight-100: #212E42; // main

// Gray
$cr-theme-gray-10: #FFFFFF;
$cr-theme-gray-20: #F2F2F2; // main
$cr-theme-gray-30: #D8D8D8; // main
$cr-theme-gray-40: #C4C0C0;
$cr-theme-gray-50: #AFAAAA;
$cr-theme-gray-60: #9B9393;
$cr-theme-gray-70: #867E7E;
$cr-theme-gray-80: #726969;
$cr-theme-gray-90: #5E5555;
$cr-theme-gray-100: #494141;

// Red
$cr-theme-red-10: #FFEDE8;
$cr-theme-red-20: #FFC2C2;
$cr-theme-red-30: #FF9999;
$cr-theme-red-40: #FF7C6A;
$cr-theme-red-50: #FF5740; // main
$cr-theme-red-60: #F54E16;
$cr-theme-red-70: #CC3300;
$cr-theme-red-80: #A32900;
$cr-theme-red-90: #7A1F00;
$cr-theme-red-100: #521400;

  // Brand colors
$cr-brand-primary: $cr-theme-orange-50;
$cr-brand-primary-dark: $cr-theme-orange-70;
$cr-brand-secondary: $cr-theme-sea-70;
$cr-brand-success: $cr-theme-blue-60;
$cr-brand-info: $cr-theme-blue-60;
$cr-brand-warning: $cr-theme-orange-50;
$cr-brand-danger: $cr-theme-red-50;
$cr-brand-default: $cr-theme-midnight-100;

// Buttons
$btn-default-color: $cr-brand-default;
$btn-default-background-color: $cr-theme-gray-10;
$btn-default-border-color: #818186;

$btn-primary-background-color: $cr-brand-primary;
$btn-secondary-background-color: $cr-brand-secondary;
$btn-success-background-color: $cr-brand-success;
$btn-info-background-color: $cr-brand-info;
$btn-warning-background-color: $cr-brand-warning;
$btn-danger-background-color: $cr-brand-danger;

// resolutions
$desktop: 1920px;
$small-desktop: 1600px;
$tablet: 1024px;
$mobile: 768px;
$small-mobile: 480px;

@use '../assets/styles/variables' as *;

@mixin resize($iconSize) {
  & svg {
    width: $iconSize;
    height: $iconSize;
  }
}

.svg-icon {
  display: flex;
  align-items: center;

  & svg {
    width: 24px;
    height: 24px;
  }

  // SIZE
  &--size-xs {
    @include resize(16px);
  }

  &--size-s {
    @include resize(20px);
  }

  &--size-m {
    @include resize(24px);
  }

  &--size-l {
    @include resize(28px);
  }

  &--size-xxl {
    @include resize(78px);
  }

  // THEME
  &--theme-primary {
    & svg {
      fill: $cr-brand-primary;
    }
  }

  &--theme-primary-dark {
    & svg {
      fill: $cr-brand-primary-dark;
    }
  }

  &--theme-secondary,
  &--theme-success { // change this case if needed
    & svg {
      fill: $cr-brand-secondary;
    }
  }

  &--theme-info {
    & svg {
      fill: $cr-brand-success;
    }
  }

  &--theme-danger {
    & svg {
      fill: $cr-brand-danger;
    }
  }

  &--theme-white {
    & svg {
      fill: $cr-theme-gray-10;
    }
  }

  &--theme-black {
    & svg {
      fill: $cr-theme-midnight-100;
    }
  }

   &--theme-default {
     & svg {
       fill: $cr-theme-midnight-60;
     }
   }
}

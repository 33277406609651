@use '../../assets/styles/variables' as *;

@mixin dropdown-menu-theme() {
  color: $cr-theme-midnight-100;
  font-size: 14px;
  text-align: left;
  background-color: $cr-theme-gray-10;
  border-radius: 4px;
  border: 1px solid $cr-theme-gray-20;
  box-shadow: 0px -4px 18px rgba(0, 32, 51, 0.07), 0px 12px 16px rgba(0, 32, 51, 0.07), 0px 16px 32px rgba(0, 32, 51, 0.07);
  min-width: 120px;
}

// TODO: add mixins
.tippy-box[data-theme="dropdown"] {
  @include dropdown-menu-theme();
  pointer-events: auto;
  overflow-y: auto;
  max-height: var(--popper-max-height);
}

.tippy-box[data-theme="dropdown"] .tippy-content {
  padding: 0;
}

.tippy-box[data-theme="dropdown"][data-rounded^=true] {
  border-radius: 10px;
}

.tippy-box[data-theme="dropdown"][data-placement^=top] > .tippy-arrow {
  border-top-color: #fff;
}

.tippy-box[data-theme="dropdown"][data-placement^=bottom] > .tippy-arrow {
  border-bottom-color: #fff;
}

.tippy-box[data-theme="dropdown"][data-placement^=left] > .tippy-arrow {
  border-left-color: #fff;
}

.tippy-box[data-theme="dropdown"][data-placement^=right] > .tippy-arrow {
  border-right-color: #fff;
}

.dropdown {
  &__content-wrapper {
    min-height: 24px;
    position: relative;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    color: $cr-theme-midnight-100;
    opacity: 0.3;
    transition: 0.3s ease;

    &:focus, &:hover {
      opacity: 1;
    }
  }

  &--rounded {
    border-radius: 10px !important;
  }
}

input.audio-track {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: theme('colors.midnight.60');
  cursor: pointer;
  height: 2px;
  outline: none;
  position: relative;
  width: 100%;
}

input.audio-track::-webkit-slider-runnable-track {
  min-inline-size: 0px;
  align-self: center;
  box-sizing: border-box;
  display: block;
  -webkit-user-modify: read-only !important;
  flex: 1 1 0%;
}

input.audio-track::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: theme('colors.midnight.90');
  border-radius: 8px;
  cursor: pointer;
  height: 8px;
  width: 8px;
}

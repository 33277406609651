@use '../../assets/styles/variables' as *;

.cr-modal-new {
  $overlay-opacity: 0.8;
  $body-padding-x: 42px;

  &__body--opened {
    overflow: hidden;
  }

  &__overlay {
    background-color: rgba(0, 0, 0, $overlay-opacity);
    display: flex;
    justify-content: center;
    align-items: center;
    animation-name: showing;
    animation-duration: 0.1s;
    padding-top: 10px;
    padding-bottom: 10px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
  }

  &__content {
    color: $cr-theme-midnight-100;
    min-width: 100px;
    min-height: 100px;
    border-radius: 16px;
    background-color: $cr-theme-gray-10;
    animation-name: zoomIn;
    animation-duration: 0.1s;
    transform-origin: 50% 0;
    position: relative;
    margin: 30px;

    &:focus {
      outline: none;
    }
  }

  &__close-button {
    position: absolute;
    padding: 2px;
    top: 4px;
    right: 4px;
  }
}

@use '/src/assets/styles/variables';

.select {
  &__control {
    .cr-form-control__input-container {
      background-color: #fff;
      border: 1px solid variables.$cr-theme-midnight-50;
    }

    &--disabled .cr-form-control__input-container {
      color: #7C93B7;
      background-color: #F9FBFF;
    }
  }

  &__collapse-icon {
    transition: 0.2s ease;

    &--opened {
      transform: rotate(-180deg);
    }
  }

  &__options {
    max-height: 300px;
    overflow-y: auto;
    padding: 12px 0;
  }

  &__option-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__options-item {
    padding: 4px 18px;
    margin-top: 2px;
    width: 100%;
    font-size: 17px;
    line-height: 24px;

    &:hover {
      background-color: variables.$cr-theme-orange-10;
    }
  }
}

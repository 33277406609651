@use 'src/assets/styles/variables';

.new-calendar {
  background: variables.$cr-theme-midnight-10;
  border-radius: 20px;
  padding: 30px 20px;
  width: 66%;
  .react-calendar {
    &__navigation__label span {
      font-size: 22px;
      color: variables.$cr-theme-midnight-90;
    }
    &__tile abbr {
      padding: 18px 0;
      min-width: 60px;
      line-height: 24px;
    }
    &__tile--active abbr {
      background-color: variables.$cr-theme-orange-50;
      color: #fff;
      border-radius: 50%;
      &::after {
        content: none !important;
      }
    }
    &__tile--now {
      font-weight: normal;
    }
    &__month-view__days__day--neighboringMonth {
      color: variables.$cr-theme-midnight-40; 
    }
    &__month-view__days__day {
      border: .5px solid variables.$cr-theme-midnight-20;
      border-top: 0;
      font-size: 14px; 
      height: 80px;

      &:nth-of-type(7n) {
        border-right: 0;
      }
      &:nth-of-type(7n+1) {
        border-left: 0;
      }
      &:nth-of-type(n+36):nth-of-type(-n+42) { // hmmmm
        border-bottom: 0;
      }
    }
    &__navigation {
      justify-content: space-evenly;
      margin-bottom: 22px;
    }
    &__month-view__weekdays__weekday {
      abbr:where([title]) {
        text-decoration: none !important;
      }
    }
    
  }
}

.new-calendar {
  &__now-dot {
    .react-calendar {
      &__tile--now {
        abbr {
          position: relative;
          &::after {
            content: '';
            position: absolute;
            bottom: 4px;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 6px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: variables.$cr-theme-red-40;
          }
        }
      }
    }
  }
  
}
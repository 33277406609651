@use '../../assets/styles/variables';

.form-helper-text {
  display: block;
  color: variables.$cr-theme-midnight-100;
  font-size: 10px;
  font-weight: normal;
  margin-top: 4px;
  margin-left: 16px;

  &--error {
    color: variables.$cr-brand-danger;
    animation-name: showing;
    animation-duration: 0.2s;
  }

  @keyframes showing {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

@use "./variables" as *;
@use "mixins/common" as *;

@tailwind base;

@layer base {
  @font-face {
    font-family: 'ProximaNova';
    font-weight: 200;
    src: url('../../../public/fonts/ProximaNovaT-Thin.otf') format("opentype");
  }
  @font-face {
    font-family: 'ProximaNova';
    font-weight: 300;
    src: url('../../../public/fonts/ProximaNova-Light.otf') format("opentype");
  }
  @font-face {
    font-family: 'ProximaNova';
    font-weight: 400;
    src: url('../../../public/fonts/ProximaNova-Regular.otf') format("opentype");
  }
  @font-face {
    font-family: 'ProximaNova';
    font-weight: 600;
    src: url('../../../public/fonts/ProximaNova-Semibold.otf') format("opentype");
  }
  @font-face {
    font-family: 'ProximaNova';
    font-weight: 700;
    src: url('../../../public/fonts/ProximaNova-Bold.otf') format("opentype");
  }
  @font-face {
    font-family: 'ProximaNova';
    font-weight: 800;
    src: url('../../../public/fonts/ProximaNova-Extrabld.otf') format("opentype");
  }

  button, [type='button'], [type='reset'], [type='submit'] {
    background-color: inherit;
  }
}

@tailwind components;
@tailwind utilities;

*, html, body {
  font-family: 'ProximaNova', proxima-nova, sans-serif;
}

.check {
  border: 1px solid #d3d3d3;
  cursor: pointer;
}

.checkbox:checked+.check {
  border-color: #1240ab;
  border-width: 3px;
}

.checkbox:checked+.check .checkIcon {
  visibility: visible;
}

.checkIcon {
  visibility: hidden;
}

.jodit-container:not(.jodit_inline) {
  border: 1px solid #dadada;
  border-radius: 9px;
  overflow: hidden;
}

.jodit-toolbar-editor-collection .jodit-toolbar-button {
  margin: 0;
  padding: 0;
}

.jodit-toolbar-collection, .jodit-toolbar-editor-collection {
  flex-direction: row;
}

.jodit-toolbar-button {
  height: 30px;
  min-width: 30px;
}

.jodit-toolbar-button button {
  height: 30px !important;
  min-width: 30px !important;
}

.h-185 {
  height: calc(100vh - 185px);
}

.loading-wrapper {
  position: relative;

  &__spinner-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: inherit;
  }

  &__spinner {
    animation: fadeIn infinite linear;
    animation-duration: .6s;
  }
}

@keyframes fadeIn {
  100% {
    transform: rotate(360deg);
  }
}

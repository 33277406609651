@use '../../assets/styles/variables' as *;
@use '../../assets/styles/mixins/common';

@mixin radio-size-theme(
  $activeSize,
  $labelMarginLeft,

  $boxSize,
  $boxBorderWidth,

  $dotSize,
) {
  .radio {
    &__label {
      margin-left: $labelMarginLeft;
    }
  }

  &.radio--labeled {
    padding-left: $activeSize + $labelMarginLeft;
  }

  &.radio--labeled input + span  {
    margin-left: -($activeSize + $labelMarginLeft);
  }

  input {
    & + span {
      padding-left: $activeSize;
      height: $activeSize;
      line-height: $activeSize;

      &::before {
        $spacing: calc(#{($activeSize - $dotSize)} / 2);

        top: $spacing;
        left: $spacing;
        width: $dotSize;
        height: $dotSize;
      }

      &::after {
        $spacing: calc(#{($activeSize - $boxSize)} / 2);

        width: $boxSize;
        height: $boxSize;
        border-width: $boxBorderWidth;
        top: $spacing;
        left: $spacing;
      }
    }
  }
}

@mixin radio-theme-theme(
  $dotColor,
  $boxBackgroundColor,
  $boxBorderColor,

  $dotCheckedColor,
  $boxCheckedBackgroundColor,
  $boxCheckedBorderColor,

  $dotDisabledColor,
  $boxDisabledBackgroundColor,
  $boxDisabledBorderColor,
) {
  input {
    & + span {
      &::before {
        border-color: $dotColor;
      }

      &::after {
        border-color: $boxBorderColor;
        background-color: $boxBackgroundColor;
      }
    }

    &:checked {
      & + span {
        &::before {
          background-color: $dotCheckedColor;
        }

        &::after {
          background-color: $boxCheckedBackgroundColor;
          border-color: $boxCheckedBorderColor;
        }
      }
    }

    &:disabled {
      & + span {
        &::before {
          background-color: $dotDisabledColor;
        }

        &::after {
          background-color: $boxDisabledBackgroundColor;
          border-color: $boxDisabledBorderColor;
        }
      }
    }

    &:disabled:not(:checked) {
      & + span {
        &::before {
          background-color: $boxDisabledBackgroundColor;
        }
      }
    }
  }
}

// Styles
.radio {
  display: inline-block;
  margin: 0;

  input {
    position: absolute;
    opacity: 0;
    pointer-events: none;

    & + span {
      position: relative;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      user-select: none;

      &::before,
      &::after {
        content: '';
        left: 0;
        position: absolute;
        border-radius: 50%;
        transition: background-color 0.25s,
        box-shadow 0.25s;
        z-index: 1;
      }

      &::before {
      }

      &::after {
        border-style: solid;
        top: 0;
        z-index: 0;
      }
    }

    &:disabled {
      & + span {
        cursor: not-allowed;
      }
    }

    &:focus {
      & + span {
        &::after {
          @include common.focus-ring-theme();
        }
      }
    }
  }

  &__label {
    vertical-align: middle;
    font-weight: normal;
    color: $cr-theme-midnight-100;
    font-size: 17px;
    line-height: 24px;
  }

  @include radio-theme-theme(
    $dotColor: $cr-theme-gray-10,
    $boxBackgroundColor: $cr-theme-gray-10,
    $boxBorderColor: $cr-theme-midnight-90,

    $dotCheckedColor: $cr-theme-orange-50,
    $boxCheckedBackgroundColor: $cr-theme-gray-10,
    $boxCheckedBorderColor: $cr-theme-orange-50,

    $dotDisabledColor: $cr-theme-orange-40,
    $boxDisabledBackgroundColor: $cr-theme-gray-10,
    $boxDisabledBorderColor: $cr-theme-orange-40,
  );

  @include radio-size-theme(
    $activeSize: 20px,
    $labelMarginLeft: 12px,

    $boxSize: 20px,
    $boxBorderWidth: 1px,

    $dotSize: 8px,
  );

  // Size
  &--size-s {
    @include radio-size-theme(
      $activeSize: 16px,
      $labelMarginLeft: 12px,

      $boxSize: 16px,
      $boxBorderWidth: 1px,

      $dotSize: 6px,
    );
  }

  &--size-m {
  }

  &--size-l {
  }

  &--size-xl {
  }
}

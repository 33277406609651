.matrix-html-formatted h1,
.matrix-html-formatted h2,
.matrix-html-formatted h3,
.matrix-html-formatted h4,
.matrix-html-formatted h5,
.matrix-html-formatted h6 {
  font-weight: 700;
  line-height: 1.4;
  margin-top: 0.75em;
  margin-bottom: 4px;
}

.matrix-html-formatted h1,
.matrix-html-formatted h2,
.matrix-html-formatted h3 {
  font-size: 1.5em;
}
.matrix-html-formatted h4 {
  font-size: 1.25em;
}
.matrix-html-formatted h5,
.matrix-html-formatted h6 {
  font-size: 1em;
}

.matrix-html-formatted code {
  color: theme('colors.white');
  background-color: theme('colors.midnight.80');
  border: 1px solid theme('colors.midnight.80');
  border-radius: 4px;
  line-height: 1.375rem;
  padding: 1px 4px;
  font-size: 0.75rem;
}

.matrix-html-formatted a {
  color: theme('colors.orange.90');
}
.matrix-html-formatted a:hover {
  text-decoration: underline;
}

.matrix-html-formatted p {
  margin-bottom: 8px;
}

.matrix-html-formatted > :last-child {
  margin-bottom: 0 !important;
}

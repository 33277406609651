@use '../../assets/styles/mixins/adaptive';
@use "../../assets/styles/mixins/inputs";
@use "../../assets/styles/variables" as *;

.tab-item {
  width: 100%;
  height: 100%;
  border-bottom: 2px solid $cr-theme-midnight-20;
  padding: 0 18px 12px;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  color: $cr-theme-midnight-70;
  transition: .2s;
  flex-grow: 1;
  white-space: nowrap;

  &--active {
    border-bottom: 2px solid $cr-theme-midnight-100;
    color: $cr-theme-midnight-100;

    & svg {
      fill: $cr-theme-midnight-100 !important;
    }
  }

  &:hover {
    color: $cr-theme-midnight-60;
  }

  &:focus {
    @include inputs.focus-ring-theme();
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  &__name {
    margin-left: 6px;
    font-size: 16px;
    line-height: 1.5;
  }

  @include adaptive.respond(mobile) {
    margin-top: 20px;
  }
}

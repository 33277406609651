@use '../../../../node_modules/tippy.js/dist/tippy.css' as *;
@use '../../../../node_modules/tippy.js/animations/shift-away.css' as *;
@use '../../../assets/styles/variables' as *;

@mixin tippy-background-theme($color, $background-color) {
  color: $color;
  background-color: $background-color;

  .tippy-arrow {
    color: $background-color;
  }
}

.tippy-box[data-theme~="cr"] {
  font-size: 12px;
  border-radius: 4px;
  padding: 7px 15px;

  @include tippy-background-theme($cr-theme-gray-20, $primary-bg);

  .tippy-content {
    overflow-wrap: break-word;
  }
}

.tippy-box[data-theme~="interactive"]  {
  pointer-events: auto;
}

.tippy-box[data-theme~="preformatted"] {
  .tippy-content {
    text-align-last: left;
    white-space: pre-wrap;
  }
}

// Theme
.tippy-box[data-theme~="danger"] {
  @include tippy-background-theme($cr-theme-gray-20, $cr-brand-danger);
}

.tippy-box[data-theme~="white"] {
  width: 294px;
  border: 1px solid #E1E3E5;
  padding: 10px 6px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

  @include tippy-background-theme($cr-theme-midnight-100, white);
}


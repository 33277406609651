@use '../../assets/styles/variables' as *;

@mixin checkbox-size-theme(
  $activeSize,
  $labelMarginLeft,
  $labelFontSize,

  $boxSize,
  $boxBorderRadius,
  $boxBorderWidth,

  $checkMarkThickness,
  $checkMarkWidth,
  $checkMarkHeight,
  $checkMarkTop,
  $checkMarkLeft,

  $indeterminateMarkWidth,
  $indeterminateMarkHeight,
  $indeterminateMarkTop,
  $indeterminateMarkLeft,
) {
  $spacing: calc(#{($activeSize - $boxSize)} / 2);

  .checkbox {
    &__label {
      font-size: $labelFontSize;
      margin-left: $labelMarginLeft;
    }
  }

  &.checkbox--labeled {
    padding-left: $activeSize + $labelMarginLeft;
  }

  &.checkbox--labeled input + span  {
    margin-left: -($activeSize + $labelMarginLeft);
  }

  input {
    &:indeterminate {
      & + span {
        &::before {
          top: $indeterminateMarkTop;
          left: $indeterminateMarkLeft;
          width: $indeterminateMarkWidth;
          border-bottom-width: $indeterminateMarkHeight;
        }
      }
    }

    & + span {
      padding-left: $activeSize;
      height: $activeSize;
      line-height: $activeSize;

      &::before {
        top: $checkMarkTop;
        left: $checkMarkLeft;
        width: $checkMarkWidth;
        height: $checkMarkHeight;
        border-right-width: $checkMarkThickness;
        border-bottom-width: $checkMarkThickness;
      }

      &::after {
        width: $boxSize;
        height: $boxSize;
        border-width: $boxBorderWidth;
        border-radius: $boxBorderRadius;
        top: $spacing;
        left: $spacing;
      }
    }
  }
}


@mixin checkbox-theme-theme (
  $checkMarkColor,
  $boxBackgroundColor,
  $boxBorderColor,

  $checkMarkCheckedColor,
  $boxCheckedBackgroundColor,
  $boxCheckedBorderColor,

  $checkMarkDisabledColor,
  $boxDisabledBackgroundColor,
  $boxDisabledBorderColor,
) {
  input {
    & + span {
      &::before {
        border-color: $checkMarkColor;
      }

      &::after {
        border-color: $boxBorderColor;
        background-color: $boxBackgroundColor;
      }
    }

    &:indeterminate {
      & + span {
        &::after {
          background-color: $boxCheckedBackgroundColor;
          border-color: $boxCheckedBorderColor;
        }
      }
    }

    &:checked {
      & + span {
        &::before {
          border-color: $checkMarkCheckedColor;
        }

        &::after {
          background-color: $boxCheckedBackgroundColor;
          border-color: $boxCheckedBorderColor;
        }
      }
    }

    &:disabled {
      & + span {
        &::before {
          border-color: $checkMarkDisabledColor;
        }

        &::after {
          background-color: $boxDisabledBackgroundColor;
          border-color: $boxDisabledBorderColor;
        }
      }
    }

    &:disabled:indeterminate,
    &:disabled:checked {
      & + span {
        //&::before {
        //  border-color: #fff;
        //}

        &::after {
          background-color: $boxDisabledBorderColor;
          //border-color: $boxDisabledBorderColor;
        }
      }
    }
  }

  &.checkbox--highlight-on-hover:hover,
  &.checkbox--highlight-on-hover.checkbox--hovered {
    input:not(:checked) + span {
      &::before {
        border-color: $checkMarkCheckedColor;
      }

      &::after {
        background-color: $boxCheckedBackgroundColor;
        border-color: $boxCheckedBorderColor;
        opacity: 0.3;
      }
    }
  }
}

.checkbox {
  $size: 18px;
  $label-margin: 11px;

  display: inline-block;
  margin: 0;

  & + & {
    margin-top: 0;
  }

  input {
    position: absolute;
    opacity: 0;
    pointer-events: none;

    & + span {
      position: relative;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      user-select: none;

      &::before,
      &::after {
        content: '';
        left: 0;
        position: absolute;
        transition: background-color 0.25s,
        box-shadow 0.25s;
        z-index: 1;
      }

      &::before {
        border-style: solid;
        border-width: 0;
        transform: rotateZ(45deg);
        transform-origin: 100% 100%;
      }

      &::after {
        border-style: solid;
        top: 0;
        z-index: 0;
      }
    }

    &:indeterminate {
      & + span {
        &::before {
          height: 0;
          border-top: none;
          border-right: none;
          border-left: none;
          transform: rotateZ(0);
          transform-origin: 100% 100%;
          transition: none;
        }
      }
    }

    &:disabled {
      & + span {
        cursor: not-allowed;
      }
    }

    &:focus {
      & + span {
        &::after {
          box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  &--labeled {
  }

  &__label {
    vertical-align: middle;
    font-weight: normal;
    user-select: none;
  }

  @include checkbox-theme-theme(
    $checkMarkColor: $cr-theme-gray-10,
    $boxBackgroundColor: $cr-theme-gray-10,
    $boxBorderColor: $cr-theme-midnight-100,

    $checkMarkCheckedColor: $cr-theme-gray-10,
    $boxCheckedBackgroundColor: $cr-theme-orange-50,
    $boxCheckedBorderColor: $cr-theme-orange-50,

    $checkMarkDisabledColor: $cr-theme-midnight-40,
    $boxDisabledBackgroundColor: $cr-theme-midnight-20,
    $boxDisabledBorderColor: $cr-theme-midnight-20,
  );

  @include checkbox-size-theme(
    $activeSize: 24px,
    $labelMarginLeft: 12px,
    $labelFontSize: 17px,

    $boxSize: 20px,
    $boxBorderRadius: 4px,
    $boxBorderWidth: 1px,

    $checkMarkThickness: 2px,
    $checkMarkWidth: 6px,
    $checkMarkHeight: 13px,
    $checkMarkTop: 4px,
    $checkMarkLeft: 4px,

    $indeterminateMarkWidth: 10px,
    $indeterminateMarkHeight: 2px,
    $indeterminateMarkTop: 9px,
    $indeterminateMarkLeft: 5px,
  );


  // Theme
  &--black {
    @include checkbox-theme-theme(
      $checkMarkColor: $cr-theme-gray-10,
      $boxBackgroundColor: $cr-theme-gray-10,
      $boxBorderColor: $cr-theme-midnight-100,

      $checkMarkCheckedColor: $cr-theme-gray-10,
      $boxCheckedBackgroundColor: $cr-theme-midnight-100,
      $boxCheckedBorderColor: $cr-theme-midnight-100,

      $checkMarkDisabledColor: $cr-theme-gray-20,
      $boxDisabledBackgroundColor: $cr-theme-gray-20,
      $boxDisabledBorderColor: $cr-theme-gray-40,
    );
  }

  &--silver-sand {
    @include checkbox-theme-theme(
      $checkMarkColor: $cr-theme-gray-10,
      $boxBackgroundColor: $cr-theme-gray-10,
      $boxBorderColor: #C7C8CA,

      $checkMarkCheckedColor: $cr-theme-gray-10,
      $boxCheckedBackgroundColor: $cr-theme-midnight-100,
      $boxCheckedBorderColor: $cr-theme-midnight-100,

      $checkMarkDisabledColor: $cr-theme-gray-20,
      $boxDisabledBackgroundColor: $cr-theme-gray-20,
      $boxDisabledBorderColor: $cr-theme-gray-40,
    );
  }

  // Size
  &--size-s {
    @include checkbox-size-theme(
      $activeSize: 24px,
      $labelMarginLeft: 12px,
      $labelFontSize: 14px,

      $boxSize: 16px,
      $boxBorderRadius: 4px,
      $boxBorderWidth: 1px,

      $checkMarkThickness: 2px,
      $checkMarkWidth: 5px,
      $checkMarkHeight: 9px,
      $checkMarkTop: 6px,
      $checkMarkLeft: 6px,

      $indeterminateMarkWidth: 8px,
      $indeterminateMarkHeight: 2px,
      $indeterminateMarkTop: 10px,
      $indeterminateMarkLeft: 7px,
    );
  }

  &--size-m {
  }

  &--size-l {
  }

  &--size-xl {
  }

  &--size-xxl {
  }
}

@use '/src/assets/styles/variables';
@use '/src/assets/styles/mixins/adaptive';

.react-datepicker-popper {
  z-index: 100;
}

.react-datepicker-time {
  &__header {
    color: variables.$cr-theme-midnight-100 !important;
  }
}

.react-datepicker {
  padding: 24px;
  border: none !important;
  box-shadow: 0px -4px 18px rgba(0, 32, 51, 0.07), 0px 12px 16px rgba(0, 32, 51, 0.07), 0px 16px 32px rgba(0, 32, 51, 0.07);
  border-radius: 6px;
  width: 400px;

  @include adaptive.respond(mobile) {
    width: 320px;
  }

  &--time-only {
    width: 100%;
  }

  &__triangle {
    display: none;
  }

  &__month-container {
    float: unset !important;
  }

  &__header {
    background-color: variables.$cr-theme-gray-10 !important;
    border: none !important;
    padding: 0 !important;
  }

  &__day-names {
    margin-top: 15px;
  }

  &__week,
  &__day-names {
    display: flex;
    width: 100%;
  }

  &__day-name,
  &__day {
    flex-grow: 1;
    font-size: 17px;
    line-height: 24px !important;
    margin: 0 !important;
    padding: 12px;

    @include adaptive.respond(mobile) {
      padding: 8px;
    }
  }

  &__day-name {
    color: variables.$cr-theme-midnight-40 !important;
  }

  &__day {
    color: variables.$cr-theme-midnight-100 !important;
    border-radius: 0 !important;

    &--in-range {
      background-color: variables.$cr-theme-gray-10 !important;
    }

    &--keyboard-selected,
    &--selected,
    &--in-selecting-range {
      color: variables.$cr-theme-midnight-50 !important;
      background-color: variables.$cr-theme-midnight-20 !important;
    }

    &--selecting-range-start,
    &--selecting-range-end {
      color: variables.$cr-theme-midnight-50 !important;
      background-color: variables.$cr-theme-midnight-30 !important;
    }

    &:hover {
      color: variables.$cr-theme-midnight-70 !important;
      background-color: variables.$cr-theme-gray-20 !important;
    }

    &--disabled {
      color: variables.$cr-theme-gray-40 !important;

      &:hover {
        color: variables.$cr-theme-gray-40 !important;
        background-color: variables.$cr-theme-gray-20 !important;
      }
    }

    &--outside-month {
      opacity: 0;
    }
  }

  &__month {
    margin: 10px 0 0 0 !important;
  }

  &__time {
    margin-top: 8px;
  }

  &__time-list-item {
    border-radius: 4px;
    color: variables.$cr-theme-midnight-100 !important;

    &--selected {
      background-color: variables.$cr-theme-midnight-40 !important;
    }

    &--disabled {
      color: variables.$cr-theme-gray-50 !important;
    }

    &:hover {
      background-color: variables.$cr-theme-gray-20 !important;
    }
  }
}

// custom styles
.date-picker {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 17px;
    line-height: 24px;
    color: variables.$cr-theme-midnight-90;
  }
}

@use "src/assets/styles/variables" as *;
@use "src/assets/styles/mixins/common" as *;

[role="button"],
button,
.cr-btn {
  cursor: pointer;
}

button,
.cr-btn {
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  padding: 14px 40px;
  border: 1px solid transparent;
  border-radius: 8px;
  outline: none;
  transition: background-color 0.2s ease,
              border-color 0.2s ease,
              color 0.2s ease;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.25;
}

.cr-btn {
  justify-content: center;
  user-select: none;

  &,
  &:focus,
  &:hover {
    text-decoration: none;
  }

  &--rounded {
    border-radius: 9999px;
  }
}

button[disabled],
.cr-btn--disabled {
  cursor: not-allowed;
  pointer-events: none;
}

@mixin resizeIcon($padding) {
  &.cr-btn--icon {
    padding: $padding;
  }
}

// Size
.cr-btn {
  &--size-xs {
    font-size: 12px;
    padding: 7px 30px;

    @include resizeIcon(9px);
  }

  &--size-s {
    font-size: 12px;
    padding: 10px 40px;

    @include resizeIcon(12px);
  }

  &--size-m {
    @include resizeIcon(16px);
  }

  &--size-l {
    padding: 20px 40px;

    @include resizeIcon(22px);
  }
}

@mixin coloredIcon($color) {
  & > .svg-icon {
    & svg {
      fill: $color;
    }
  }
}

@mixin _colored-btn(
  $backgroundColor,
  $hoverBackgroundColor,
  $color: $cr-theme-gray-10,
  $hoverColor: $color,
  $borderColor: $backgroundColor,
  $hoverBorderColor: $hoverBackgroundColor,
  $focusColor: $color,
  $focusBackgroundColor: $backgroundColor,
  $focusBorderColor: $focusBackgroundColor,
  $disabledColor: $hoverBackgroundColor,
  $disabledBackgroundColor,
  $disabledBorderColor: $disabledBackgroundColor,
  $activeColor: $color,
  $activeBackgroundColor,
  $activeBorderColor: $activeBackgroundColor,
) {
  background-color: $backgroundColor !important;
  color: $color;
  border-color: $borderColor;
  background-image: none;
  text-shadow: none;
  @include coloredIcon($color);

  &[disabled],
  &.cr-btn--disabled {
    color: $disabledColor;
    background-color: $disabledBackgroundColor !important;
    border-color: $disabledBorderColor;
    @include coloredIcon($disabledColor);
  }

  &:not([disabled]),
  &:not(.cr-btn--disabled) {
    &:hover {
      color: $hoverColor;
      background-color: $hoverBackgroundColor !important;
      border-color: $hoverBorderColor;
      @include coloredIcon($hoverColor);
    }

    &:active {
      color: $activeColor;
      background-color: $activeBackgroundColor !important;
      border-color: $activeBorderColor;
      @include coloredIcon($activeColor);
    }

    &:focus {
      color: $focusColor;
      background-color: $focusBackgroundColor !important;
      border-color: $focusBorderColor;
      @include coloredIcon($focusColor);
    }
  }
}

.cr-btn--theme-primary {
  @include _colored-btn(
    $backgroundColor: $cr-theme-orange-50,
    $hoverBackgroundColor: $cr-theme-orange-40,
    $focusBackgroundColor: $cr-theme-orange-60,
    $disabledBackgroundColor: $cr-theme-orange-10,
    $activeBackgroundColor: $cr-theme-orange-70,
  )
}

.cr-btn--theme-secondary {
  @include _colored-btn(
    $backgroundColor: $cr-theme-sea-70,
    $hoverBackgroundColor: $cr-theme-sea-60,
    $focusBackgroundColor: $cr-theme-sea-80,
    $disabledBackgroundColor: $cr-theme-sea-10,
    $activeBackgroundColor: $cr-theme-sea-90,
    $disabledColor: $cr-theme-sea-70,
  )
}

.cr-btn--theme-danger {
  @include _colored-btn(
    $backgroundColor: $cr-theme-red-60,
    $hoverBackgroundColor: $cr-theme-red-60,
    $focusBackgroundColor: $cr-theme-red-80,
    $disabledBackgroundColor: $cr-theme-red-10,
    $activeBackgroundColor: $cr-theme-red-90,
    $disabledColor: $cr-theme-red-70,
  )
}

.cr-btn--theme-outline-danger {
  @include _colored-btn(
    $color: $cr-theme-red-60,
    $borderColor: $cr-theme-red-60 !important,
    $backgroundColor: 'transparent',
    $hoverBackgroundColor: $cr-theme-red-20,
    $hoverBorderColor: $cr-theme-red-60,
    $hoverColor: $cr-theme-red-60,
    $focusBackgroundColor: $cr-theme-red-10,
    $disabledBackgroundColor: 'transparent',
    $disabledBorderColor: $cr-theme-red-20,
    $activeBackgroundColor: 'transparent',
    $activeBorderColor: $cr-theme-red-60,
  )
}

.cr-btn--theme-outline-primary {
  @include _colored-btn(
    $color: $cr-theme-orange-50,
    $borderColor: $cr-theme-orange-50,
    $backgroundColor: 'transparent',
    $hoverBackgroundColor: $cr-theme-orange-20,
    $hoverBorderColor: $cr-theme-orange-60,
    $hoverColor: $cr-theme-orange-60,
    $focusBackgroundColor: $cr-theme-orange-10,
    $disabledBackgroundColor: 'transparent',
    $disabledBorderColor: $cr-theme-orange-20,
    $activeBackgroundColor: 'transparent',
    $activeBorderColor: $cr-theme-orange-60,
  )
}

.cr-btn--theme-clear {
  @include _colored-btn(
    $backgroundColor: 'transparent',
    $hoverBackgroundColor: $cr-theme-midnight-30,
    $color: $cr-theme-midnight-100,
    $borderColor: 'transparent',
    $focusBackgroundColor: $cr-theme-midnight-20,
    $disabledColor: $cr-theme-midnight-40,
    $disabledBackgroundColor: 'transparent',
    $activeBackgroundColor: $cr-theme-midnight-40,
  )
}

.cr-btn--theme-ghost {
  @include _colored-btn(
    $backgroundColor: $cr-theme-midnight-10,
    $hoverBackgroundColor: $cr-theme-midnight-30,
    $color: $cr-theme-midnight-100,
    $hoverBorderColor: $cr-theme-midnight-30,
    $focusBackgroundColor: $cr-theme-midnight-20,
    $disabledColor: $cr-theme-midnight-40,
    $disabledBackgroundColor: $cr-theme-midnight-10,
    $activeBackgroundColor: $cr-theme-midnight-40,
  )
}

.cr-chevron-btn {
  border-radius: 8px;
  border: 2px solid $cr-theme-orange-50;

  button {
    border-radius: 8px 0px 0px 8px;
  }

  & > .svg-icon {
    padding: 0 8px;
    
    & svg {
      fill: $cr-theme-orange-50;
    }
  }
}
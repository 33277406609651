@use '../variables';

@mixin respond($breakpoint) {
  @if $breakpoint==large-desktop {
    @media only screen and (min-width: variables.$desktop) {
      @content;
    }
  }

  @if $breakpoint==small-desktop {
    @media only screen and (max-width: variables.$small-desktop) {
      @content;
    }
  }

  @if $breakpoint==tablet {
    @media only screen and (max-width: variables.$tablet) {
      @content;
    }
  }

  @if $breakpoint==mobile {
    @media only screen and (max-width: variables.$mobile) {
      @content;
    }
  }

  @if $breakpoint==small-mobile {
    @media only screen and (max-width: variables.$small-mobile) {
      @content;
    }
  }
}

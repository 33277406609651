@use '../../assets/styles/mixins/inputs';

.cr-textarea-input {
  @include inputs.input-theme();
  resize: vertical;
  min-height: 34px;
  min-width: 100%;

  &--resize-none {
    resize: none;
  }

  &--resize-both {
    resize: both;
  }

  &--resize-horizontal {
    resize: horizontal;
  }

  &--resize-vertical {
    resize: vertical;
  }
}

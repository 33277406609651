@use "../../assets/styles/variables" as *;

@mixin switcher-size-theme(
  $width,
  $height,
  $margin,
  $check-margin-border,
  $border-size,
  $label-margin-left,
) {
  $check-margin: $margin + $border-size + $check-margin-border;
  $check-size: $height - ($check-margin * 2);

  input {
    &:checked {
      & + span {
        &::before {
          transform: translateX($width - $check-size - ($check-margin * 2));
        }
      }
    }
  }

  input + span {
    padding-left: $width;
    height: $height;
    line-height: $height;

    &::before {
      top: $check-margin;
      left: $check-margin;
      width: $check-size;
      height: $check-size;
    }

    &::after {
      width: $width - ($margin * 2);
      height: $height - ($margin * 2);
      border-width: $border-size;
      border-radius: calc(#{$height} / 2);
      top: $margin;
      left: $margin;
    }
  }

  .switcher__label {
    margin-left: $label-margin-left;
  }
}


@mixin switcher-theme-theme(
  $inactive-color,
  $active-color,
  $background-color,
  $inactive-background-color: $cr-theme-gray-10,
  $disabled-active-background-color: $cr-theme-orange-40,
  $disabled-active-color,
  $disabled-inactive-color,
  $label-inactive-color,
  $label-active-color,
) {
  input {
    &:checked {
      & + span {
        &::before {
          background-color: $active-color;
        }

        &::after {
          border-color: $background-color;
        }
      }
    }

    &:disabled {
      & + span {
        &::before {
          background-color: $disabled-active-color;
        }

        &::after {
          border-color: $disabled-active-color;
          background-color: $disabled-active-background-color;
        }
      }
    }

    &:disabled:not(:checked) {
      & + span {
        &::before {
          background-color: $disabled-inactive-color;
        }

        &::after {
          border-color: $disabled-inactive-color;
        }
      }
    }
  }

  input + span {
    &::before {
      background-color: $inactive-color;
    }

    &::after {
      background-color: $background-color;
      border-color: $inactive-color;
    }
  }

  input:not(:checked) + span {
    &::after {
      background-color: $inactive-background-color;
    }
  }

  .switcher__label {
    color: $label-inactive-color;
  }

  input:checked ~ .switcher__label {
    color: $label-active-color;
  }
}

.switcher {
  display: inline-block;
  margin: 0;
  position: relative;

  & + & {
    margin-top: 0;
  }

  input {
    position: absolute;
    opacity: 0;
    pointer-events: none;

    &:disabled {
      & + span {
        cursor: not-allowed;
      }
    }

    &:focus {
      & + span {
        &::after {
          box-shadow: 0 0 0 2px rgba(0, 26, 255, 0.1);
        }
      }
    }
  }

  input + span {
    position: relative;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    user-select: none;

    &::before,
    &::after {
      content: '';
      left: 0;
      position: absolute;
      transition: border 0.25s,
                  background-color 0.25s,
                  box-shadow 0.25s,
                  left 0.20s 0.1s,
                  transform 0.20s 0.1s;
      z-index: 1;
    }

    &::before {
      border-radius: 50%;
    }

    &::after {
      border-style: solid;
      z-index: 0;
    }
  }

  &__label {
    font-weight: normal;
    vertical-align: middle;
  }

  @include switcher-theme-theme(
    $inactive-color: $cr-theme-midnight-50,
    $active-color: $cr-theme-gray-10,
    $background-color: $cr-theme-orange-50,
    $disabled-active-color: $cr-theme-gray-10,
    $disabled-inactive-color: $cr-theme-midnight-40,
    $label-inactive-color: $cr-theme-midnight-40,
    $label-active-color: $cr-theme-midnight-100,
  );

  @include switcher-size-theme(
    $width: 44px,
    $height: 24px,
    $margin: 0,
    $check-margin-border: 2px,
    $border-size: 1px,
    $label-margin-left: 8px,
  );


  // Theme
  &--theme-silver-sand {
    @include switcher-theme-theme(
      $inactive-color: $cr-theme-gray-30,
      $active-color: #fff,
      $background-color: transparent,
      $disabled-active-color: $cr-theme-gray-30,
      $disabled-inactive-color: $cr-theme-gray-30,
      $label-inactive-color: $cr-theme-gray-30,
      $label-active-color: #fff,
    );
  }


  // Size
  &--size-s {
    @include switcher-size-theme(
      $width: 26px,
      $height: 17px,
      $margin: 2px,
      $check-margin-border: 1px,
      $border-size: 1px,
      $label-margin-left: 4px,
    );
  }

  &--size-m {
  }

  &--size-l {
  }

  &--size-xl {
  }

  &--size-xxl {
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .button-main,
  .button-second,
  .button-outline,
  .button-clear,
  .button-ghost {
    @apply flex items-center rounded-lg font-semibold outline-none transition-colors ease-in-out duration-200;
    padding-left: var(--button-padding-x);
    padding-right: var(--button-padding-x);
    padding-top: var(--button-padding-y);
    padding-bottom: var(--button-padding-y);
    font-size: inherit;
    line-height: inherit;
  }
  .button-main.button-icon-rect,
  .button-second.button-icon-rect,
  .button-outline.button-icon-rect,
  .button-clear.button-icon-rect,
  .button-ghost.button-icon-rect {
    padding-left: var(--button-padding-x-rect);
    padding-right: var(--button-padding-x-rect);
    padding-top: var(--button-padding-y-rect);
    padding-bottom: var(--button-padding-y-rect);
  }
  .button-main.button-icon-circle,
  .button-second.button-icon-circle,
  .button-outline.button-icon-circle,
  .button-clear.button-icon-circle,
  .button-ghost.button-icon-circle {
    padding-left: var(--button-padding-x-circle);
    padding-right: var(--button-padding-x-circle);
    padding-top: var(--button-padding-y-circle);
    padding-bottom: var(--button-padding-y-circle);
  }
  .button-main {
    @apply text-white bg-orange-50 focus-within:bg-orange-60 hover:bg-orange-40 active:bg-orange-70 disabled:bg-orange-10 disabled:text-orange-40;
  }
  .button-second {
    @apply text-white bg-sea-70 focus-within:bg-sea-80 hover:bg-sea-60 active:bg-sea-90 disabled:bg-sea-10 disabled:text-sea-70;
  }
  .button-outline {
    @apply text-orange-50 bg-white border border-orange-50 focus-within:bg-orange-10 hover:bg-orange-20 hover:text-orange-60 hover:border-orange-60 active:bg-white active:border-orange-60 active:text-orange-50 disabled:border-orange-20 disabled:text-orange-20;
  }
  .button-clear {
    @apply text-midnight-100 bg-transparent focus-within:bg-midnight-20 hover:bg-midnight-30 active:bg-midnight-40 disabled:text-midnight-40 disabled:bg-transparent;
  }
  .button-ghost {
    @apply text-midnight-100 bg-midnight-10 focus-within:bg-midnight-20 hover:bg-midnight-30 active:bg-midnight-40 disabled:text-midnight-40 disabled:bg-midnight-10;
  }
  .button-danger {
    @apply text-white bg-red-60 focus-within:bg-red-80 hover:bg-red-40 active:bg-red-90 disabled:bg-red-10 disabled:text-red-40;
  }
  .button-l {
    --button-padding-x: theme(space.10);
    --button-padding-y: 1.375rem;
    --button-padding-x-rect: 1.375rem;
    --button-padding-y-rect: 1.375rem;
    --button-padding-x-circle: 1.375rem;
    --button-padding-y-circle: 1.375rem;
  }
  .button-m {
    --button-padding-x: theme(space.10);
    --button-padding-y: theme(space.4);
    --button-padding-x-rect: theme(space.5);
    --button-padding-y-rect: theme(space.4);
    --button-padding-x-circle: theme(space.4);
    --button-padding-y-circle: theme(space.4);
  }
  .button-s {
    --button-padding-x: theme(space.10);
    --button-padding-y: theme(space.3);
    --button-padding-x-rect: theme(space.5);
    --button-padding-y-rect: theme(space.3);
    --button-padding-x-circle: theme(space.3);
    --button-padding-y-circle: theme(space.3);
  }
  .button-xs {
    --button-padding-x: 1.875rem;
    --button-padding-y: 0.4375rem;
    --button-padding-x-rect: theme(space.3);
    --button-padding-y-rect: 0.4375rem;
    --button-padding-x-circle: 0.5625rem;
    --button-padding-y-circle: 0.4375rem;
    @apply text-xs;
  }

  .button-l-rect,
  .button-l-circle {
    @apply button-l;
  }
  .button-m-rect {
    @apply button-m;
  }
  .button-m-circle {
    @apply button-m;
  }
  .button-s-rect {
    @apply button-s;
  }
  .button-s-circle {
    @apply button-s;
  }
  .button-xs-rect {
    @apply button-xs;
  }
  .button-xs-circle {
    @apply button-xs;
  }

  .input-main {
    @apply bg-gray-10 disabled:bg-midnight-10
      border rounded-md border-midnight-50 default:border-midnight-50 disabled:border-midnight-30 focus:border-orange-50 
      text-midnight-100 placeholder:text-midnight-40 disabled:text-midnight-60
      focus:outline-none ring-orange-50 focus:ring-1;
  }
  .input-main:not(.input-custom-invalid) {
    @apply invalid:border-red-60 focus:invalid:border-red-60 invalid:ring-red-60;
  }
  .input-main[aria-invalid]:not([aria-invalid='false']):not(.input-custom-invalid) {
    @apply border-red-60 focus:border-red-60 ring-red-60;
  }
  .input-m {
    @apply px-4 py-3 text-lg leading-6;
  }
  .input-s {
    @apply px-4 py-2 leading-6;
  }
}

@layer utilities {
  .number-no-spinner::-webkit-inner-spin-button,
  .number-no-spinner::-webkit-outer-spin-button {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
  }
}

@use '../../assets/styles/mixins/inputs' as *;
@use '../../assets/styles/variables';

.cr-form-control {
  display: inline-flex;
  flex-direction: column;
  vertical-align: top;
  z-index: 0;

  &__input-container {
    @include input-container-theme();

    &:hover {
      @include input-container-hovered-theme();
    }
  }

  &__input {
    border: none;
    width: 100%;
    font-size: 100%;
    padding: 14px 16px;
    margin: 0;
    background-color: transparent;

    &:focus {
      outline: none;
    }

    &:-webkit-autofill {
      border-radius: 6px;
    }

    &::placeholder {
      @include input-placeholder-theme();
    }
  }

  &__input-start-adornment {
    padding-left: 16px;
    flex-shrink: 0;

    & svg {
      fill: variables.$cr-theme-midnight-60 !important;
    }
  }

  &__input-end-adornment {
    padding-right: 7px;
    flex-shrink: 0;


    & svg {
      fill: variables.$cr-theme-midnight-60 !important;
    }
  }


  // Size
  &--size-s &__input-container {
    font-size: 15px !important;
    line-height: 1.6 !important;
  }

  &--size-s &__input {
    padding: 4px 16px;
  }

  &--size-s &__input-end-adornment {
    padding-right: 6px;
  }

  &--size-s &__input-start-adornment {
    padding-right: 6px;
  }

  &--full-width {
    width: 100%;
  }

  &--focused {
  }

  &--focused &__input-container {
    @include input-container-focused-theme();
  }


  &--disabled {
  }

  &--disabled &__input-container {
    @include input-container-disabled-theme();
  }


  &--readonly {
  }

  &--readonly &__input-container {
    @include input-container-readonly-theme();
  }


  &--required {
  }


  &--error {
  }

  &--error &__input-container,
  &--error .cr-textarea-input,
  &--error .cr-textarea-input:hover,
  &--error .cr-textarea-input:focus {
    @include input-container-error-theme();
  }
}

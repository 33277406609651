@use '../../assets/styles/variables';

.form-control-label {
  margin: 0;

  &__label {
    display: block;
    color: variables.$cr-theme-midnight-100;
    margin-bottom: 10px;
    font-size: 17px;
    line-height: 1.47;
    font-weight: 600;
  }

  // Label placement
  &--placement-top {}

  &--placement-right {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  &--placement-right &__label {
    margin: 0 0 0 18px;
  }

  &--placement-left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &--placement-left &__label {
    margin: 0 18px 0 0;
  }
}
